






























































import {Component, Vue} from "vue-property-decorator";
import {GOOGLE_MAPS} from "@/constants/APIKeys";
import PortalCollapse from "@/components/request/w9cr/PortalCollapse.vue";

@Component({
  components: {PortalCollapse}
})
export default class Locations extends Vue {

  get key(): string | undefined {
    return GOOGLE_MAPS;
  }

}

